<template>
  <v-container id="calendar-page" fluid>
    <v-row class="fill-height">
      <v-col>
        <v-sheet>
          <v-row>
            <v-col lg="6" md="6" sm="12">
              <v-toolbar class="toolbar-flex" flat>
                <v-btn class="mr-4" color="grey darken-2" outlined @click="setToday">
                  {{ $t('buttons.today') }}
                </v-btn>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="grey darken-2" outlined v-bind="attrs" v-on="on">
                      <span>{{ $t(typeToLabel[type]) }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = changeTypeOfCalendar('day')">
                      <v-list-item-title> {{ $t('filters.day') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = changeTypeOfCalendar('week')">
                      <v-list-item-title> {{ $t('filters.week') }} </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = changeTypeOfCalendar('month')">
                      <v-list-item-title> {{ $t('filters.month') }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div class="toolbar-flex-div"></div>
                <v-btn color="grey darken-2" fab small text @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn color="grey darken-2" fab small text @click="next">
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ formatTitle($refs.calendar.title) }}
                </v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="4" v-if="user.role_id != 11">
              <v-autocomplete
                v-model="driver_id"
                :items="drivers"
                clearable
                item-text="full_name"
                item-value="id"
                :label="$t('filters.filterByDriver')"
                @change="loadAllDrivings"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="4" v-if="user.role_id != 11">
              <v-autocomplete
                v-model="vehicle_id"
                :items="allVehicles"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('filters.filterByVehicle')"
                @change="loadAllDrivings"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="4" v-if="user.role_id != 11">
              <v-autocomplete
                v-model="client_id"
                :items="allClients"
                clearable
                item-text="name"
                item-value="id"
                :label="$t('filters.filterByClient')"
                @change="loadAllDrivings"
                :no-data-text="$t('select.noDataAvailable')"
                @focus="$event.target.click()"></v-autocomplete>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="focus"
            :events="events"
            :interval-format="intervalFormat"
            :type="type"
            color="primary"
            interval-count="48"
            interval-height="60"
            interval-minutes="30"
            @change="updateRange"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @click:time="showTime"
            :weekdays="weekDays"
            :weekday-format="getDay"
            :month-format="getMonth"
            :first-day-of-week="2"
            style="height: 600px">
            <template v-slot:event="{ event }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <div class="event">
                      <v-img
                        v-if="event.gnetData"
                        alt="GNET"
                        class="shrink mr-3"
                        contain
                        src="@/assets/gnet-small.png"
                        transition="scale-transition"
                        height="18px"
                        width="20px" />
                      <v-img
                        v-if="event?.affiliate?.affiliate_data_record"
                        alt="Affiliate"
                        class="shrink mr-3"
                        contain
                        src="@/assets/limo-small.png"
                        transition="scale-transition"
                        height="18px"
                        width="20px" />
                      <p
                        :class="
                          event.gnetData && event?.affiliate?.affiliate_data_record
                            ? 'ps-14'
                            : event.gnetData || event?.affiliate?.affiliate_data_record
                            ? 'ps-7'
                            : 'px-1'
                        ">
                        <v-icon v-if="event.paid" size="21px"> mdi-cash-check </v-icon>
                        {{ user?.role_id === 11 ? event.public_number : event.number }}
                        {{
                          event?.start_display_formated && type === 'month' ? '- ' + event?.start_display_formated : ''
                        }}
                        {{ event?.vehicle?.plate_number ? '- ' + event?.vehicle?.plate_number : '' }}
                        {{
                          JSON.parse(event?.from_location)?.name && type === 'month'
                            ? '- ' + JSON.parse(event?.from_location)?.name
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                </template>

                <driving-info-tooltip :item="event" :drivers="drivers" />
              </v-tooltip>
            </template>
          </v-calendar>
          <div class="statuses d-flex justify-center flex-wrap mt-10">
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#ECC600" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.pending') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#3CB503" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.accepted') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#868686" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.done') }} / {{ $t('drivingStatus.canceled') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#F46D6D" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.rejected') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#7520FF" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.draft') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#E1A750" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.expired') }}
            </div>
            <div class="d-flex align-items-center mx-3">
              <v-icon color="#8B0000" class="me-2 status-circle">mdi-circle</v-icon>
              {{ $t('drivingStatus.noShow') }}
            </div>
          </div>
        </v-sheet>
      </v-col>

      <driving-modal
        v-if="
          this.$store.state.auth.role === 1 || this.$store.state.auth.role === 2 || this.$store.state.auth.role === 4
        "
        :selectedElement="selectedElement"
        :transferOrDaily="selectedElement?.drivingType?.id ? selectedElement?.drivingType?.id : 1" />

      <customer-driving-modal
        v-if="this.$store.state.auth.role === 11"
        :selectedElement="selectedElement"
        :transferOrDaily="selectedElement?.drivingType?.id ? selectedElement?.drivingType?.id : 1" />
    </v-row>
  </v-container>
</template>

<script>
import { defaultDriving } from '@/mixins/default-items';
import DrivingModal from './DrivingModal.vue';
import CustomerDrivingModal from '@/components/customers/drivings/CustomersDrivingModal.vue';
import i18n from '@/i18n/i18n';
import { formatDateTime } from '@/utils/formatDate';
import { changeColorBasedOnProposalStatus } from '@/utils/changeColorBasedOnProposalStatus';
import DrivingInfoTooltip from '@/components/drivings/DrivingInfoTooltip.vue';

export default {
  name: 'CalendarView',
  components: { DrivingModal, CustomerDrivingModal, DrivingInfoTooltip },
  props: [],
  data: () => ({
    focus: '',
    type: 'week',
    typeToLabel: {
      month: 'filters.month',
      week: 'filters.week',
      day: 'filters.day',
    },
    driving: {},
    selectedElement: null,
    events: [],
    vehicle_id: null,
    driver_id: null,
    client_id: null,
    vehicle: {},
    drivers: [],
    allVehicles: [],
    allClients: [],
    min: null,
    max: null,
    user: {},
  }),

  created() {
    this.selectedElement = Object.assign({}, defaultDriving);

    this.checkLoadingAndOpenDrivingModal();

    this.user = this.$store.getters['auth/user'];

    if (this.user.role_id !== 11) {
      this.getAllVehicles();
      this.getAllDrivers();
      this.getAllClients();
    }

    if (this.user.defaultCalendarType) {
      this.type = this.user.defaultCalendarType;
    }
  },

  mounted() {
    this.$refs.calendar.checkChange();
  },

  methods: {
    showSingleDriving(id) {
      this.$store.dispatch('drivings/getDriving', id).then((res) => {
        res.data.color = changeColorBasedOnProposalStatus(res.data?.drivingProposal?.drivingProposalStatus);

        // we keep original value of pickupt time for comparing date
        res.data.pickup_time_original = res.data.pickup_time;
        // we keep format pickup time for preview
        res.data.pickup_time = formatDateTime(res.data.pickup_time);
        res.data.expected_drop_off_time = formatDateTime(res.data.expected_drop_off_time);
        res.data.expected_comeback_time = formatDateTime(res.data.expected_comeback_time);
        res.data.created_at = formatDateTime(res.data.created_at);
        res.data.driving_number = res.data.number + ` [${res.data.public_number}]`;
        this.showEvent({ nativeEvent: false, event: res.data });
      });
    },

    async loadAllDrivings() {
      let queryData = {
        from: this.min,
        to: this.max,
        vehicle_id: this.vehicle_id,
        driver_id: this.driver_id,
        client_id: this.client_id,
      };

      let path = 'getAllDrivings';

      if (this.user.role_id == 11) {
        path = 'getAllCustomerDrivings';
      }

      await this.$store.dispatch('drivings/' + path, queryData).then((res) => {
        this.events = res.data.map((e) => {
          e.distance = parseFloat(e.distance);
          e.price = parseFloat(e.price);
          e.paid = parseInt(e.paid);
          let proposalStatus = e?.drivingProposal?.drivingProposalStatus;
          e.color = changeColorBasedOnProposalStatus(proposalStatus);
          // we keep original value of pickupt time for comparing date
          e.pickup_time_original = e.pickup_time;
          // we keep format pickup time for preview
          e.pickup_time = formatDateTime(e.pickup_time);
          e.expected_drop_off_time = formatDateTime(e.expected_drop_off_time);
          e.expected_comeback_time = formatDateTime(e.expected_comeback_time);
          e.created_at = formatDateTime(e.created_at);
          e.driving_number = e.number + ` [${e.public_number}]`;

          return e;
        });
      });
    },

    async getAllVehicles() {
      await this.$store.dispatch('vehicles/getAllVehicles').then((res) => {
        this.allVehicles = res.data;
      });
    },

    async getAllDrivers() {
      await this.$store.dispatch('users/getAllUsers', { drivers: true }).then((res) => {
        this.drivers = res.data.length
          ? res.data.map((user) => {
              user.full_name = user.profile?.full_name || 'N/A';
              return user;
            })
          : [];
      });
    },

    async getAllClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.allClients = res.data.length
          ? res.data.map((client) => {
              return client;
            })
          : [];
      });
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    setToday() {
      this.focus = new Date();
      this.type = 'day';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    showTime(event) {
      if (!this.disabledForAffiliate) {
        this.selectedElement = Object.assign({}, { ...defaultDriving, pickup_time: `${event.date} ${event.time}` });
      }
    },

    showEvent({ nativeEvent, event }) {
      if (event) {
        this.selectedElement = Object.assign({}, event);
      } else {
        this.selectedElement = Object.assign({}, defaultDriving);
      }

      if (nativeEvent) {
        nativeEvent.stopPropagation();
      }
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}`).toISOString();
      const max = new Date(`${end.date}`).toISOString();
      this.min = min;
      this.max = max;
      this.loadAllDrivings();
    },

    intervalFormat(interval) {
      return interval.time;
    },

    changeTypeOfCalendar(type) {
      if (type === 'day' || type === 'week') {
        setTimeout(() => this.$refs.calendar.scrollToTime(475), 100);
      }
      return type;
    },
    getDay(date) {
      const weekdayTranslations = {
        0: 'weekDays.sunday',
        1: 'weekDays.monday',
        2: 'weekDays.tuesday',
        3: 'weekDays.wednesday',
        4: 'weekDays.thursday',
        5: 'weekDays.friday',
        6: 'weekDays.saturday',
      };

      return i18n.t(weekdayTranslations[date.weekday]).slice(0, 3);
    },
    getMonth(date) {
      const monthsTranslations = {
        1: 'months.jan',
        2: 'months.feb',
        3: 'months.mar',
        4: 'months.apr',
        5: 'months.may',
        6: 'months.jun',
        7: 'months.jul',
        8: 'months.aug',
        9: 'months.sep',
        10: 'months.oct',
        11: 'months.nov',
        12: 'months.dec',
      };

      return i18n.t(monthsTranslations[date.month]).slice(0, 3);
    },
    formatTitle(title) {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      const monthsTranslations = {
        0: 'months.jan',
        1: 'months.feb',
        2: 'months.mar',
        3: 'months.apr',
        4: 'months.may',
        5: 'months.jun',
        6: 'months.jul',
        7: 'months.aug',
        8: 'months.sep',
        9: 'months.oct',
        10: 'months.nov',
        11: 'months.dec',
      };

      let month = title.slice(0, -5);
      const year = title.slice(-4);

      monthNames.map((item, index) => {
        if (item === month) {
          month = monthsTranslations[index];
        }
      });
      return i18n.t(month) + ' ' + year;
    },

    checkLoadingAndOpenDrivingModal() {
      // Check if there is a driving_id
      if (this.$route.query.driving_id) {
        if (this.$store.state.loader.loading) {
          setTimeout(this.checkLoadingAndOpenDrivingModal, 100); // Check every 100 milliseconds
        } else {
          // Once loading is false, call the showSingleDriving method
          this.showSingleDriving(this.$route.query.driving_id);
        }
      }
    },
  },
  watch: {
    '$store.state.addedNewDrivingCounter': {
      immediate: false,
      handler() {
        this.loadAllDrivings();
      },
    },
    '$store.state.notificationDriving.id': {
      handler() {
        this.showSingleDriving(this.$store.state.notificationDriving.id);
        this.$store.commit('updateNotificationDrivingReload');
        this.loadAllDrivings();
      },
      deep: false,
    },
    '$store.state.notificationDriving.reload': {
      handler() {
        if (this.$store.state.notificationDriving.reload) {
          this.showSingleDriving(this.$store.state.notificationDriving.id);
          this.$store.commit('updateNotificationDrivingReload');
          this.loadAllDrivings();
        }
      },
      deep: false,
    },
    type(val) {
      const user = this.$store.getters['auth/user'];

      const updatedUser = {
        ...user,
        defaultCalendarType: val,
      };
      this.$store.dispatch('auth/setUser', updatedUser);
      localStorage.setItem('LimoExpressUser', JSON.stringify(updatedUser));
    },
  },
};
</script>

<style lang="scss">
#calendar-page {
  .v-event-timed {
    max-height: 20px;
    max-width: fit-content;
  }

  .v-calendar .v-event-timed {
    display: inline-flex;
  }

  @media only screen and (max-width: 480px) {
    .toolbar-flex > .v-toolbar__content {
      flex-wrap: wrap;
    }

    .toolbar-flex-div {
      flex-basis: available;
      margin-right: 100%;
    }
  }

  .event {
    position: relative;
  }

  .event .v-image {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
